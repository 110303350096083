<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <!-- {{dataForm}} -->
    <el-form :model="dataForm" ref="dataForm" label-width="80px">
      <el-form-item label="名称" prop="label">
        <el-input v-model="dataForm.name" placeholder="名称"></el-input>
      </el-form-item>
      <div v-for="col in colsData.cols" :key="col.prop" >
        <el-form-item v-if="col.type == 'dt'" :label="col.label" :prop="col.prop">
          <el-select v-model="dataForm.data[col.prop]" clearable value-key="title">
              <el-option v-for="(dt,index) in getDtdata()" :key="index" :label="dt.title" :value="dt"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else-if="col.type == 'sys'" :label="col.label" :prop="col.prop">
          <!-- {{dataForm.data[col.prop]}} -->
          <el-select v-model="dataForm.data[col.prop]" multiple>
              <el-option label="公用" value="系统公用"></el-option>
              <el-option v-for="(name,index) in getSysdata()" :key="index" :label="name" :value="name"></el-option>
          </el-select>

          <!-- <el-checkbox v-model="dataForm.data[col.prop]" label="sysAll">公用</el-checkbox> -->
        </el-form-item>
        <el-form-item v-else-if="col.type == 'textarea'" :label="col.label" :prop="col.prop">
          <el-input v-model="dataForm.data[col.prop]" type="textarea" placeholder="请输入" :autosize="{minRows: 5, maxRows: 20}"></el-input>
        </el-form-item>
        <el-form-item v-else-if="col.type == 'boolean'" :label="col.label" :prop="col.prop">
          <el-switch v-model="dataForm.data[col.prop]" ></el-switch>
        </el-form-item>
        <el-form-item v-else-if="col.type == 'img'" :label="col.label" :prop="col.prop">
          <upload-img v-model="dataForm.data[col.prop]"/>
        </el-form-item>
        <div v-else-if="col.type == 'menu'">
          <draggable v-model="dataForm.data.items">
          <div class="item" v-for="(item,index) in dataForm.data.items" :key="index">
            <el-form-item label="按钮名称">
              <el-select v-model="item.commandName" filterable allow-create>
                <el-option v-for="(name,index) in getCndata()" :key="index" :label="name" :value="name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="控制码">
              <el-select v-model="item.commandBytes"  filterable allow-create>
                  <el-option v-for="(option,index) in getCommandData()" 
                  :key="index" :label="option.name" :value="option.data.commandBytes">
                  </el-option>
              </el-select>
              <!-- <el-input v-model="item.commandBytes" placeholder="请输入"></el-input> -->
            </el-form-item>
            <i class="el-icon-close" @click="delItem(index)"></i>
          </div>
          </draggable>
          <el-button type="primary" @click="addItem()">添加条目</el-button>
        </div>
        <el-form-item v-else-if="col.type == 'byteCode'" :label="col.label" :prop="col.prop">
          <el-input v-model="dataForm.data[col.prop]" placeholder="请输入" @change="bcChange(dataForm.data,col.prop)"></el-input>
        </el-form-item>
        <el-form-item v-else-if="col.type == 'btnClazz'" :label="col.label" :prop="col.prop">
          <el-select v-model="dataForm.data[col.prop]" clearable>
              <el-option label="一级按钮" value="1"></el-option>
              <el-option label="二级按钮" value="2"></el-option>
              <el-option label="自定义二级" value="3"></el-option>
          </el-select>
          <upload-img v-if="dataForm.data[col.prop]==3" style="margin-top: 10px;" v-model="dataForm.data.clazzImg"/>
        </el-form-item>
        <el-form-item v-else :label="col.label" :prop="col.prop">
          <el-input v-model="dataForm.data[col.prop]" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import draggable from 'vuedraggable'
  import uploadImg from '@/components/upload-img'
  import { formatCode } from '@/utils'
  export default {
    components: {
      draggable,
      uploadImg
    },
    data () {
      return {
        visible: false,
        dataForm: {},
        colsData: {},
        dtData: null, // 类别数据
        sysData: null, // 所属系统数据
        cnData: null, // 按钮名称数据
        commandData: null, // 按钮数据
        sysIsCommon: false // 系统是否是公共
      }
    },
    methods: {
      init (colsData, id) {
        this.colsData = colsData
        this.dataForm = {
          name: '',
          data: {}
        }
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/bus/busdata/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                data.busData.data = JSON.parse(data.busData.data)
                this.dataForm = data.busData
              }
            })
          }
        })
      },
      getDtdata() { // 获取类别数据
        if (!this.dtData) {
          this.$http({
            url: this.$http.adornUrl(`/bus/busdata/list/deviceType`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              let dtData = []
              for (var row of data.list) {
                row.data = JSON.parse(row.data)
                dtData.push({title: row.name, deviceType: row.data.value})
              }
              this.dtData = dtData
            }
          })
        }
        return this.dtData || []
      },
      getSysdata() { // 获取系统数据
        if (!this.sysData) {
          this.$http({
            url: this.$http.adornUrl(`/bus/bussystem/getAllList`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              let sysData = []
              for (var row of data.list) {
                sysData.push(row.name)
              }
              this.sysData = sysData
            }
          })
        }
        return this.sysData || []
      },
      getCndata() { // 获取按钮名称
        if (!this.cnData) {
          this.$http({
            url: this.$http.adornUrl(`/bus/busdata/list/commandName`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              let cnData = []
              for (var row of data.list) {
                row.data = JSON.parse(row.data)
                cnData.push(row.name)
              }
              this.cnData = cnData
            }
          })
        }
        return this.cnData || []
      },
      getCommandData() { // 获取按钮数据
        if (!this.commandData) {
          this.$http({
            url: this.$http.adornUrl(`/bus/busdata/list/commandBytes`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              let commandData = []
              for (var row of data.list) {
                row.data = JSON.parse(row.data)
                commandData.push(row)
              }
              this.commandData = commandData
            }
          })
        }
        return this.commandData || []
      },
      // 添加条目
      addItem() {
        var items = Object.assign([], this.dataForm.data.items)
        items.push({})
        this.$set(this.dataForm.data, 'items', items)
      },
      delItem(index) {
        var items = Object.assign([], this.dataForm.data.items)
        items.splice(index, 1)
        this.$set(this.dataForm.data, 'items', items)
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/bus/busdata/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || null,
                'name': this.dataForm.name,
                'code': this.colsData.code,
                'data': JSON.stringify(this.dataForm.data),
                'sort': this.dataForm.sort
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      bcChange(data, key) {
        this.$set(data, key, formatCode(data[key]))
      }
    }
  }
</script>
<style scoped lang="scss">
  .item{
    display: flex;
    background-color: #f2f6fc;
    padding-top: 20px;
    margin-bottom: 10px;
    position: relative;
    .el-icon-close{
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 18px;
    }
  }
</style>
